import { bg_footer, ic_fb, ic_instagram, ic_youtube } from "../assets/layout";
import {
  EM_Charitable_trust,
  pp_savani_group,
  pp_savani,
  yojana_Logo,
} from "../assets";
import React, { useEffect } from "react";
import { socialLinks } from "../lib/index";
import { SocialLink, SocialLinks } from "../utils";
import { Link } from "react-router-dom";
import siteMetadata from "../utils/siteMetaData";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialities } from "../features/Specialities/specialitiesApi";

const FooterSection = ({ title, items }) => {
  return (
    <>
      <h1 className="border-b pb-3 text-xl border-[#EF0E5B]">{title}</h1>
      <ul className="grid grid-cols-2 xl:gap-4 gap-2 mt-5 font-medium xl:text-base lg:text-sm text-xs ">
        {items.map((item, index) => (
          <li key={index} className="truncate" title={item}>
            ● {item.title}
          </li>
        ))}
      </ul>
    </>
  );
};

const QuickLinkSection = ({ title, items }) => {
  return (
    <>
      <h1 className="border-b pb-3 text-xl border-[#EF0E5B]">{title}</h1>
      <ul className="grid grid-cols-2 xl:gap-4 gap-2 mt-5 font-medium xl:text-base lg:text-sm text-xs ">
        {items.map((item, index) => (
          <li key={index} className="truncate" title={item.title}>
            <Link to={item.path}>● {item.title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

const Footer = () => {
  const dispatch = useDispatch();
  const { specialities } = useSelector((state) => state.specialities);
  const specialtiesData = specialities?.data || [];

  useEffect(() => {
    dispatch(getSpecialities());
  }, [dispatch]);

  // Define the routes you want to display in the footer
  const footerRoutes = [
    { path: "/", title: "Home" },
    { path: "/find-doctors", title: "Find Doctors" },
    { path: "/blogs", title: "Blog" },
    { path: "/visitors", title: "Visitors Guidelines" },
    { path: "/carrer", title: "Career" },
    { path: "/privacy-policy", title: "Privacy Policy" },
  ];

  return (
    <>
      <footer className="flex flex-col items-center justify-between">
        <section
          className="p-5 bg-no-repeat min-h-creen min-w-full bg-cover"
          style={{
            backgroundImage: `url(${bg_footer})`,
            backgroundSize: "cover",
          }}
        >
          <div className="xl:container mx-auto text-white font-poppins xl:mt-11 mt-5">
            <div className="grid md:grid-cols-9 sm:grid-cols-2 grid-cols-1 gap-4 border-b border-b-[#D5D5D5] pb-6">
              <Link
                to={"/"}
                className="md:col-span-4 bg-white w-full h-fit px-5 py-4 rounded-3xl flex justify-evenly items-center"
              >
                {[EM_Charitable_trust, pp_savani_group, pp_savani].map(
                  (logo, index) => (
                    <React.Fragment key={index}>
                      <img
                        src={logo}
                        alt="pp_savani_group"
                        className="2xl:w-32 2xl:h-32 h-20 w-20"
                      />
                      {index < 2 && (
                        <div className="h-16 w-[0.4px] bg-primary" />
                      )}
                    </React.Fragment>
                  )
                )}
              </Link>

              <address className="md:col-span-4 md:col-start-1 md:row-start-2 font-medium xl:mt-5 space-y-3 not-italic">
                {socialLinks.map(
                  ({ href, icon, label, description, title }, index) => (
                    <SocialLink
                      key={index}
                      href={href}
                      icon={icon}
                      label={label}
                      description={description}
                      title={title}
                    />
                  )
                )}
                <div className="items-center gap-3 py-2 flex">
                  <SocialLinks
                    href={siteMetadata.fb}
                    icon={ic_fb}
                    label="Reach out to me via Facebook"
                    title="Facebook"
                  />
                  <SocialLinks
                    href={siteMetadata.instagram}
                    icon={ic_instagram}
                    label="Reach out to me via instagram"
                    title="instagram"
                  />
                  <SocialLinks
                    href={siteMetadata.youtube}
                    icon={ic_youtube}
                    label="Reach out to me via YouTube"
                    title="YouTube"
                  />
                </div>
                <img
                  src={yojana_Logo}
                  className="2xl:w-auto 2xl:h-32 h-20 w-auto"
                  alt="yojana_Logo"
                />
              </address>

              <nav className="md:col-span-4 md:col-start-1 md:row-start-3">
                <QuickLinkSection title="Quick Links :" items={footerRoutes} />
              </nav>
              <section className="md:col-span-5 md:row-span-3 md:col-start-5 md:row-start-1 xl:ml-10">
                <FooterSection
                  title="Top Specialities :"
                  items={specialtiesData}
                />
              </section>
            </div>

            <section className="grid md:grid-cols-2 grid-cols-1 xl:mt-9 mt-4 items-center text-xs">
              <article>
                <h1>Advisory Notice:</h1>
                <p>
                  1. PP Savani Hospitals never solicit information like bank
                  details or payment authorization via links. Please be cautious
                  about sharing sensitive information.
                </p>
                <p className="mt-3">
                  2. P.P. Savani Hospital does not charge for job opportunities.
                  Verify offers with official sources.
                </p>
              </article>

              <nav className="flex flex-wrap gap-2 justify-center text-xs mt-3">
                {[
                  "Privacy Policy",
                  "Disclaimer",
                  "Accessibility",
                  "Terms of Use",
                  "Sitemap",
                ].map((link, index) => (
                  <React.Fragment key={index}>
                    <Link to={`/${link.toLowerCase().replace(" ", "-")}`}>
                      {link}
                    </Link>
                    {index < 4 && "|"}
                  </React.Fragment>
                ))}
              </nav>
            </section>
          </div>
        </section>
        <div className="bg-secondary w-full md:px-5">
          <div className="xl:container mx-auto text-white py-2 font-goldplay font-medium flex sm:flex-row flex-col gap-2 items-center justify-between">
            <p className="xl:text-base text-xs">
              <span className="font-normal">Develop & Design By </span>
              MajesticAI & Pixel’s Designvilla
            </p>
            <div className="flex items-center xl:gap-8 gap-3">
              <p className="xl:text-base text-xs">
                ©P.P. SAVANI GROUP 2024.{" "}
                <span className="font-normal">All Right Reserved.</span>
              </p>
              <div className="items-center gap-2 md:flex hidden">
                <SocialLink
                  href={siteMetadata.fb}
                  icon={ic_fb}
                  label="Reach out to me via Facebook"
                  title="Facebook"
                />
                <SocialLink
                  href={siteMetadata.instagram}
                  icon={ic_instagram}
                  label="Reach out to me via instagram"
                  title="instagram"
                />
                <SocialLink
                  href={siteMetadata.youtube}
                  icon={ic_youtube}
                  label="Reach out to me via YouTube"
                  title="YouTube"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
