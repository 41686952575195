import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";

export const getCarrer = createAsyncThunk(
  "Carrer/getActive",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${config.base.url}/v1/user/carrer/get-carrer`
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch active Carrer"
      );
    }
  }
);

export const createCarrerApplication = createAsyncThunk(
  "CarrerApplication/createCarrerApplication",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${config.base.url}/v1/user/carrerApplication/create-carrerApplication`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to create Carrer Application"
      );
    }
  }
);
