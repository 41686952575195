export const cx = (...classNames) => classNames.filter(Boolean).join(" ");

export const SocialLink = ({ href, icon, label, title, description }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    title={title}
    className="flex items-start sm:gap-3 gap-1 font-poppins"
  >
    <img src={icon} alt={title} width={20} height={20} className="w-5 h-5" />
    {description && <span className="2xl:text-sm text-xs">{description}</span>}
  </a>
);

export const SocialLinks = ({ href, icon, label, title, description }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    title={title}
    className="flex items-start sm:gap-3 gap-2 font-poppins"
  >
    <img src={icon} alt={title} width={40} height={40} className="w-8 h-8 md:w-10 md:h-10" />
    {description && <span className="2xl:text-sm text-xs">{description}</span>}
  </a>
);
