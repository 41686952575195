// NavBar.js
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ic_ambulance, ic_date, ic_menu } from "../assets/layout";
import { EM_Charitable_trust, pp_savani, pp_savani_group } from "../assets";
import { navItems, additionalItems } from "../lib";
import { cx } from "../utils";
import { FaPhoneAlt, FaUserCircle } from "react-icons/fa";
import NavItem from "./index";

function NavBar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAmbulanceDropdownOpen, setIsAmbulanceDropdownOpen] = useState(false);

  const ambulanceRef = useRef(null);
  const sidebarRef = useRef(null);
  const overlayRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigateBookPage = () => {
    navigate("/book-appointment");
  };

  // Check if token is present in cookies
  const checkLoginStatus = () => {
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    return !!token;
  };

  useEffect(() => {
    setIsLoggedIn(checkLoginStatus());
  });

  const toggleAmbulanceDropdown = () => {
    setIsAmbulanceDropdownOpen(!isAmbulanceDropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ambulanceRef.current &&
        !ambulanceRef.current.contains(event.target)
      ) {
        setIsAmbulanceDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logoutHandler = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setIsLoggedIn(false);
    navigate("/login");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !overlayRef.current.contains(event.target)
      ) {
        setIsNavOpen(false);
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <main>
      {/* Desktop Nav */}
      <header className="lg:grid hidden grid-cols-10 grid-rows-3 border-b border-[#D5D5D5]">
        <Link
          to={"/"}
          className="col-span-2 row-span-3 flex gap-3 px-3 items-center justify-evenly border-r border-[#D5D5D5]"
        >
          <img
            src={pp_savani}
            alt="pp_savani"
            width={80}
            height={80}
            className="2xl:w-24 2xl:h-24 h-20 w-20"
          />
          <hr className="h-20 w-[0.4px] bg-primary" />
          <h1 className="text-xl font-semibold">P.P. SAVANI HOSPITAL</h1>
        </Link>
        <div className="col-span-7 col-start-3 flex justify-between border-b border-[#D5D5D5] px-5 py-2">
          <Link to={"/book-appointment"} className="flex items-center gap-2">
            <h1 className="font-poppins 2xl:text-sm text-xs text-primary font-semibold">
              Book Online
            </h1>
            <span className="text-secondary font-poppins font-semibold">→</span>
            <p className="text-secondary 2xl:text-sm text-xs font-poppins font-semibold">
              You can request appointment in 24 hours
            </p>
          </Link>
          <div className="text-primary text-sm font-medium font-poppins flex items-center gap-5">
            <Link to="/visitors">
              Visitor Guidelines{" "}
              <span className="text-secondary font-bold text-base">+</span>
            </Link>
            <Link to="/patient-process">
              Patients Process{" "}
              <span className="text-secondary font-bold text-base">+</span>
            </Link>
          </div>
        </div>
        <div className="col-start-10 flex gap-2 items-center border-b border-[#D5D5D5] py-2 z-[99999999]">
          <div className="relative" ref={ambulanceRef}>
            <img
              src={ic_ambulance}
              alt="ic_ambulance"
              className="cursor-pointer"
              onClick={toggleAmbulanceDropdown}
            />

            {/* Dropdown for Ambulance Number */}
            <div
              className={`absolute right-0 mt-2 w-40 bg-white shadow-lg rounded-lg transition-all transform origin-top ${
                isAmbulanceDropdownOpen
                  ? "opacity-100 scale-y-100"
                  : "opacity-0 scale-y-0"
              }`}
              style={{ transitionDuration: "300ms" }}
            >
              <div className="p-3 text-center">
                <p className="text-lg font-bold text-primary">Ambulance</p>
                <p className="text-xl font-semibold text-gray-700">
                  9925077200
                </p>
                <a
                  href="tel:9925077200"
                  className="block mt-2 text-white bg-primary px-4 py-2 rounded-md flex items-center justify-center gap-2"
                >
                  <FaPhoneAlt /> Call Now
                </a>
              </div>
            </div>
          </div>
          <div className="relative" ref={dropdownRef}>
            {/* User Icon */}
            <div onClick={toggleDropdown} className="cursor-pointer">
              <FaUserCircle
                className="text-primary"
                size={30}
                title="Login Icon"
              />
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div
                onMouseDown={(e) => e.stopPropagation()}
                className="absolute right-0 mt-2 w-48 z-50 bg-white shadow-lg rounded-lg"
              >
                <ul className="py-2">
                  <li className="px-4 py-2 hover:bg-gray-100">
                    <Link to="/book-appointment">Book Appointment</Link>
                  </li>
                  {isLoggedIn ? (
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={logoutHandler}
                    >
                      Logout
                    </li>
                  ) : (
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link to="/login">Login</Link>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-7 col-start-3 row-start-2 flex w-fit ml-auto pr-8">
          <div className="text-primary text-sm font-medium  font-poppins flex items-center gap-8 border-b">
            {additionalItems.map(({ text, pathname }) => (
              <NavItem key={text} text={text} pathname={pathname} icon="+" />
            ))}
          </div>
        </div>
        <div className="col-span-7 col-start-3 row-start-3 flex items-center justify-between px-20">
          {navItems.map(({ src, alt, text, icon, pathname }) => {
            return (
              <NavItem
                key={text}
                src={src}
                alt={alt}
                text={text}
                icon={icon}
                pathname={pathname}
              />
            );
          })}
        </div>
        <div
          onClick={navigateBookPage}
          className="relative cursor-pointer row-span-2 col-start-10 row-start-2 bg-primary font-poppins font-semibold text-white flex justify-center items-center text-center 2xl:text-sm text-xs border-2 border-transparent hover:border-white hover:shadow-[0_0_10px_2px_rgba(255,255,255,0.8)] transition-all duration-200 animate-[pulse_3s_ease-in-out_infinite]"
        >
          Book <br /> Appointment
          <img
            onClick={navigateBookPage}
            src={ic_date}
            alt="ic_date"
            className="absolute left-0 translate-x-[-50%] hover:rotate-6 transition-transform duration-300"
          />
        </div>
      </header>

      {/* Mobile Nav */}
      <nav className="lg:hidden block px-5 bg-white sticky top-0 z-[999999]">
        <div className="flex justify-between items-center gap-3">
          <img
            src={ic_menu}
            alt="ic_menu"
            width={45}
            height={45}
            onClick={() => setIsNavOpen(!isNavOpen)}
          />
          <article className="w-full h-fit px-5 py-2.5 rounded-3xl flex justify-center sm:gap-3 gap-2 items-center">
            {[EM_Charitable_trust, pp_savani_group, pp_savani].map(
              (logo, index) => (
                <React.Fragment key={index}>
                  <img
                    src={logo}
                    alt="pp_savani_group"
                    width={45}
                    height={45}
                  />
                  {index < 2 && <div className="h-6 w-[0.4px] bg-primary" />}
                </React.Fragment>
              )
            )}
          </article>
          <ul className="flex items-center justify-end gap-2">
            <li className="relative" ref={ambulanceRef}>
              <img
                src={ic_ambulance}
                alt="ic_ambulance"
                className="cursor-pointer w-24 h-20"
                onClick={toggleAmbulanceDropdown}
              />

              {/* Dropdown for Ambulance Number */}
              <div
                className={`absolute right-0 mt-2 w-40 bg-white shadow-lg rounded-lg transition-all transform origin-top ${
                  isAmbulanceDropdownOpen
                    ? "opacity-100 scale-y-100"
                    : "opacity-0 scale-y-0"
                }`}
                style={{ transitionDuration: "300ms" }}
              >
                <div className="p-3 text-center">
                  <p className="text-lg font-bold text-primary">Ambulance</p>
                  <p className="text-xl font-semibold text-gray-700">
                    9925077200
                  </p>
                  <a
                    href="tel:9925077200"
                    className="block mt-2 text-white bg-primary px-4 py-2 rounded-md flex items-center justify-center gap-2"
                  >
                    <FaPhoneAlt /> Call Now
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div className="relative" ref={dropdownRef}>
                <div onClick={toggleDropdown} className="cursor-pointer">
                  <FaUserCircle
                    className="text-primary login_icon "
                    title="Login Icon"
                  />
                </div>

                {isDropdownOpen && (
                  <div
                    onMouseDown={(e) => e.stopPropagation()}
                    className="absolute right-0 mt-2 w-48 z-50 bg-white shadow-lg rounded-lg"
                  >
                    {isDropdownOpen && (
                      <div
                        onMouseDown={(e) => e.stopPropagation()}
                        className="absolute right-0 mt-2 w-48 z-50 bg-white shadow-lg rounded-lg"
                      >
                        <ul className="py-2">
                          <li className="px-4 py-2 hover:bg-gray-100">
                            <Link to="/book-appointment">Book Appointment</Link>
                          </li>
                          {isLoggedIn ? (
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={logoutHandler}
                            >
                              Logout
                            </li>
                          ) : (
                            <li className="px-4 py-2 hover:bg-gray-100">
                              <Link to="/login">Login</Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
        <div
          ref={overlayRef}
          className={cx(
            isNavOpen ? "fixed inset-0 bg-black bg-opacity-50" : "hidden",
            "lg:hidden"
          )}
          onClick={() => setIsNavOpen(false)}
        />
        <div
          ref={sidebarRef}
          className={cx(
            isNavOpen
              ? "transform translate-x-0"
              : "transform -translate-x-full",
            "lg:hidden fixed top-0 left-0 z-50 w-64 bg-white h-full transition-transform duration-300 ease-in-out px-4 py-2 border-r border-primary shadow-lg"
          )}
        >
          <article className="bg-white w-full h-fit rounded-3xl flex justify-between sm:gap-3 gap-2 items-center my-5">
            {[EM_Charitable_trust, pp_savani_group, pp_savani].map(
              (logo, index) => (
                <React.Fragment key={index}>
                  <img
                    src={logo}
                    alt="pp_savani_group"
                    width={45}
                    height={45}
                  />
                  {index < 2 && <div className="h-6 w-[0.4px] bg-primary" />}
                </React.Fragment>
              )
            )}
          </article>
          <div className="flex flex-col items-start mt-2">
            {navItems.map(({ src, alt, text, icon, pathname }) => (
              <div key={text} className="border-b py-2 w-full">
                <Link to={pathname} onClick={() => setIsNavOpen(false)}>
                  <NavItem
                    src={src}
                    alt={alt}
                    text={text}
                    icon={icon}
                    pathname={pathname}
                  />
                </Link>
              </div>
            ))}
          </div>
          <hr className="my-3 border-secondary" />
          <div className="flex flex-col items-start mt-3">
            {additionalItems.map(({ text, pathname }) => (
              <div key={text} className="border-b py-2 w-full">
                <Link to={pathname} onClick={() => setIsNavOpen(false)}>
                  <NavItem text={text} pathname={pathname} icon="+" />
                </Link>
              </div>
            ))}
          </div>
          <hr className="my-3 border-secondary" />
          <div className="text-primary text-sm font-medium flex flex-col items-start">
            <Link
              onClick={() => setIsNavOpen(false)}
              to="/patient-process"
              className="2xl:text-sm text-xs text-primary font-semibold border-b py-2 w-full"
            >
              Patients Process <span className="text-secondary">+</span>
            </Link>
            <Link
              onClick={() => setIsNavOpen(false)}
              to="/visitors"
              className="2xl:text-sm text-xs text-primary font-semibold border-b py-2 w-full"
            >
              Visitor Guidelines <span className="text-secondary">+</span>
            </Link>
          </div>
        </div>
      </nav>
    </main>
  );
}

export default NavBar;
